import React from 'react'
import Skeletons from '@/custom/components/Skeletons'
import './Loader.css'

function ListSkeletonPack() {
    return (
        <div className="listPackViewSkeleton">
            <Skeletons type="text" />

            <Skeletons type="text" />
            <Skeletons type="text" />
        </div>
    )
}

export default ListSkeletonPack
