import React, { useEffect, useContext, useState, Suspense } from 'react'
import { Route, BrowserRouter as Router, withRouter, Switch } from 'react-router-dom'
import PrivateRoute from './privateRoute'
import Loaders from '@/custom/components/molecules/Loaders'
import Header from '@/custom/components/molecules/Header'
import Footer from '@/custom/components/molecules/Footer'
import Home from '@/views/Home'

const Login = React.lazy(() => import('@/views/Login/login'))
const ForgotPassword = React.lazy(() => import('@/views/ForgotPassword'))
const ResetPassword = React.lazy(() => import('@/views/ResetPassword'))
const Search = React.lazy(() => import('@/views/Search'))
const MyAuctions = React.lazy(() => import('@/views/Dashboard/MyAuctions'))
const MyOffers = React.lazy(() => import('@/views/Dashboard/MyOffers'))
const Registration = React.lazy(() => import('@/views/Registration'))
const Profile = React.lazy(() => import('@/views/Dashboard/Profile/index'))
const ChangePassword = React.lazy(() => import('@/views/Dashboard/Profile/ChangePassword'))
const ForceResetPassword = React.lazy(() => import('@/views/ResetPassword/forceReset'))
const MyInvoices = React.lazy(() => import('@/views/Dashboard/MyInvoices'))
const MyDeposits = React.lazy(() => import('@/views/Dashboard/MyDeposits'))
const MyTransactions = React.lazy(() => import('@/views/Dashboard/MyTransactions'))
const Invoice = React.lazy(() => import('@/views/Invoice'))
const DepositInvoice = React.lazy(() => import('@/views/DepositInvoice'))
const LiveAuction = React.lazy(() => import('@/views/ProductView/LiveAuction'))
const ProductViewInvite = React.lazy(() => import('@/views/ProductView/ProductView_Invite'))
const Cart = React.lazy(() => import('@/views/Cart'))
const BuynowCart = React.lazy(() => import('@/views/BuyNowCart'))
const Checkout = React.lazy(() => import('@/views/Checkout'))
const MyWallet = React.lazy(() => import('@/views/Dashboard/MyWallet'))
const ErrorPage = React.lazy(() => import('@/views/ErrorPage'))
const Auction = React.lazy(() => import('@/views/Auction'))
const Pack = React.lazy(() => import('@/views/Pack/index'))
const PackView = React.lazy(() => import('@/views/Pack/packView'))

const Calendar = React.lazy(() => import('@/views/Calendar/index'))
const CalendarView = React.lazy(() => import('@/views/Calendar/packView'))

const Album = React.lazy(() => import('@/views/Album/index'))
const AlbumView = React.lazy(() => import('@/views/AlbumView'))

const Offer = React.lazy(() => import('@/views/Offer'))
const VerifyEmail = React.lazy(() => import('@/views/VerifyEmail'))
const Notifications = React.lazy(() => import('@/views/Dashboard/Notifications'))
const ContactUs = React.lazy(() => import('@/views/ContactUs'))
const StaticPage = React.lazy(() => import('@/views/Static'))
const SavedSearch = React.lazy(() => import('@/views/Dashboard/SavedSearch'))
const RosoomResponse = React.lazy(() => import('@/views/Rosoom/Response'))
const MyCard = React.lazy(() => import('@/views/Dashboard/MyCard'))
const MyBank = React.lazy(() => import('@/views/Dashboard/MyBank'))
const Faq = React.lazy(() => import('@/views/Static/Faq'))
const SwitchUser = React.lazy(() => import('@/views/SwitchUser'))
const Privacy = React.lazy(() => import('@/views/Privacy'))
const Routes = () => {
    return (
        <>
            {window.location.pathname === '/' || <Header />}
            <Suspense fallback={<Loaders name="home" isLoading={true} />}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forgot_password" component={ForgotPassword} />
                    <Route exact path="/reset_password/:token" component={ResetPassword} />
                    <Route exact path="/search">
                        <Search showItems={true} filters={true} sortby={true} limit={true} />
                    </Route>
                    <Route exact path="/liveAuction/:aid" component={LiveAuction} />
                    <Route exact path="/auction" component={Auction} />
                    <Route exact path="/packs" component={Pack} />
                    <Route exact path="/pack/:aid" component={PackView} />

                    <Route exact path="/calendars" component={Calendar} />
                    <Route exact path="/calendar/:aid" component={CalendarView} />

                    <Route exact path="/album/:aid" component={AlbumView} />
                    <Route exact path="/albums" component={Album} />
                    <Route exact path="/offer" component={Offer} />
                    <Route exact path="/invite" component={ProductViewInvite} />
                    <Route exact path="/contact" component={ContactUs} />
                    <Route exact path="/saved_search" component={SavedSearch} />
                    <Route exact path="/verify_email/:token" component={VerifyEmail} />
                    <PrivateRoute exact path="/buynowcart" component={BuynowCart} />
                    <PrivateRoute exact path="/checkout/:type/:cart_id" component={Checkout} />
                    <PrivateRoute exact path="/cart" component={Cart} />
                    <PrivateRoute exact path="/auctions/:status/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/offers/:status/:type" component={MyOffers} />
                    <PrivateRoute exact path="/watchlist/:status" component={MyAuctions} />
                    <PrivateRoute exact path="/purchases/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/account/profile" component={Profile} />
                    <PrivateRoute exact path="/account/change" component={ChangePassword} />
                    <PrivateRoute exact path="/reset_password" component={ForceResetPassword} />
                    <PrivateRoute exact path="/account/notifications" component={Notifications} />
                    {/* <PrivateRoute exact path="/company_profile" component={CompanyProfile} /> */}
                    <PrivateRoute exact path="/invoices/:action" component={MyInvoices} />
                    <PrivateRoute exact path="/transactions" component={MyTransactions} />
                    <PrivateRoute exact path="/invoice/:id" component={Invoice} />
                    <PrivateRoute exact path="/depositinvoice/:id" component={DepositInvoice} />
                    <PrivateRoute exact path="/deposits/:action" component={MyDeposits} />
                    <PrivateRoute exact path="/payments/saved_cards" component={MyCard} />
                    <PrivateRoute exact path="/payments/bank_details" component={MyBank} />
                    <PrivateRoute exact path="/wallet" component={MyWallet} />
                    <Route exact path="/support" component={Faq} />

                    <Route
                        exact
                        path={[
                            '/about',
                            '/services',
                            '/help',
                            '/guidelines',
                            '/terms_of_use',
                            '/how_to_bid',
                        ]}
                        component={StaticPage}
                    />
                    <Route exact path="/privacy_policy" component={Privacy} />
                    <Route exact path="/switch" component={SwitchUser} />
                    <Route exact path="*" component={ErrorPage} />
                </Switch>
                <Footer />
            </Suspense>
            {/* <Suspense fallback={<Loaders name="home" />}> */}
            {/* </Suspense> */}
        </>
    )
}
export default withRouter(Routes)
