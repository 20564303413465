import React, { useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import CommonContext from './commonContext'
import CommonReducer from './commonReducer'
import { apiCall } from '@/product/common/api'
import { response } from '@/product/context/common'

import {
    COMMON_VALUES,
    SOCKET_MODAL,
    SOCKET_CONNECTED,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    SWITCH_LANGUAGE,
} from './commonTypes'

const CommonState = (props) => {
    const initialState = {
        allCategory: [],
        allBusinessCategory: [],
        allOrganization: [],
        allVatCategory: [],
        allLocations: [],
        allTimeZone: [],
        allEventType: [],
        allCondition: [],
        allUISettings: [],
        allSubCategories: [],
        alldata_all_dropdown: [],
        allNotifications: [],
        allfaq: [],
        allHomeBanner: [],
        allConfigurationVariables: {},
        configFeatures: {},
        configVariables: {},
        allBidIncrements: [],
        responseStatus: null,
        languages: [],
        pharases: {},
        currentLanguage: 'for_english',
        socketModal: false,
        socketConnected: null,
    }

    const { i18n } = useTranslation()

    const [state, dispatch] = useReducer(CommonReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getGlobalVariable = async (type) => {
        const [res] = await Promise.all([apiCall('post', 'getDefault', {}, '', 'common')])
        if (res?.data?.status && res.data.status === 'success') {
            dispatch({
                type: COMMON_VALUES,
                payload: {
                    allCategory: res.data.data.responseData.tableValues.allcategorytypes,
                    allSubCategories: res.data.data.responseData.tableValues.allsubCategoryTypes,
                    allOrganization: res.data.data.responseData.tableValues.allorganization,
                    allBusinessCategory:
                        res.data.data.responseData.tableValues.allbusiness_category,
                    allVatCategory: res.data.data.responseData.tableValues.allvat_registration,
                    allNotifications: res.data.data.responseData.tableValues.allall_notifications,
                    allTimeZone: res.data.data.responseData.tableValues.alltime_zone,
                    allEventType: res.data.data.responseData.tableValues.allevent_type,
                    allConfigurationVariables: res.data.data.responseData.variables,
                    allLocations: res.data.data.responseData.tableValues.alllocations,
                    allHomeBanner: res.data.data.responseData.tableValues.allhome_banner,
                    allfaq: res.data.data.responseData.tableValues.allfaq,
                    allCondition: res.data.data.responseData.tableValues.allconditiontypes,
                    allUISettings: res.data.data.responseData.tableValues.allui_settings,
                    allBidIncrements: res.data.data.responseData.allBidIncrements,
                    bidIncrementDefault: res.data.data.responseData.bidIncrementDefault,
                    alldata_all_dropdown: res.data.data.responseData.tableValues
                        .alldata_all_dropdown
                        ? res.data.data.responseData.tableValues.alldata_all_dropdown.filter(
                              (value) => value.active != 2,
                          )
                        : [],
                    configFeatures: res.data.data.responseData.features,
                    configVariables: res.data.data.responseData.variables,
                    languages: res.data.data.responseData.languages,
                    phrases: res.data.data.responseData.phrases,
                },
            })

            //set reload the language phrase
            global.languages = res.data.data.responseData.languages
            global.phrases = res.data.data.responseData.phrases
            for (let i = 0; i < global.languages.length; i++) {
                i18n.addResourceBundle(
                    global.languages[i],
                    'translations',
                    global.phrases[global.languages[i]],
                    true,
                    true,
                )
            }
            i18n.changeLanguage('for_english')
        }
    }

    const switchLanguage = (data) =>
        dispatch({
            type: SWITCH_LANGUAGE,
            payload: data,
        })

    const setSocketModal = () => {
        dispatch({
            type: SOCKET_MODAL,
        })
    }

    const setSocketConnected = () => {
        dispatch({
            type: SOCKET_CONNECTED,
        })
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <CommonContext.Provider
            value={{
                allCategory: state.allCategory,
                allBusinessCategory: state.allBusinessCategory,
                allOrganization: state.allOrganization,
                allVatCategory: state.allVatCategory,
                allNotifications: state.allNotifications,
                allSubCategories: state.allSubCategories,
                allLocations: state.allLocations,
                allCondition: state.allCondition,
                allfaq: state.allfaq,
                allHomeBanner: state.allHomeBanner,
                allTimeZone: state.allTimeZone,
                allEventType: state.allEventType,
                allUISettings: state.allUISettings,
                allBidIncrements: state.allBidIncrements,
                alldata_all_dropdown: state.alldata_all_dropdown,
                allConfigurationVariables: state.allConfigurationVariables,
                configFeatures: state.configFeatures,
                configVariables: state.configVariables,
                languages: state.languages,
                currentLanguage: state.currentLanguage,
                responseStatus: state.responseStatus,
                socketModal: state.socketModal,
                socketConnected: state.socketConnected,
                setSocketConnected,
                setSocketModal,
                switchLanguage,
                getGlobalVariable,
                clearResponse,
            }}
        >
            {props.children}
        </CommonContext.Provider>
    )
}

export default CommonState
