import React from 'react'
import Skeletons from '@/custom/components/Skeletons'

function ProductViewSkeleton() {
    return (
        <div className="productViewSkeleton albumSkeleton">
            <div className="pvsImage">
                <Skeletons type="gridImage" />
            </div>
            <div className="albumTextCnt">
                <Skeletons type="text" />
                <Skeletons type="text" />
            </div>
        </div>
    )
}

export default ProductViewSkeleton
