import React from 'react'
import Skeletons from '@/custom/components/Skeletons'

function ProductViewSkeleton() {
    return (
        <div className="productViewSkeleton">
            <div className="pvsImage imageAblbum">
                <Skeletons type="gridImage" />
            </div>
        </div>
    )
}

export default ProductViewSkeleton
