import React, { useEffect, useState, useContext } from 'react'
import './App.css'
import Routes from './routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { I18nextProvider } from 'react-i18next'
import AuthState from '@/product/context/auth/authState'
import CustomCommonState from './custom/context/common/commonState'
import ProductCommonState from '@/product/context/common/commonState'
import UserState from '@/product/context/user/userState'
import AlertState from '@/product/context/alert/alertState'
import ProductState from '@/product/context/product/productState'
import InvoiceState from '@/product/context/invoice/invoiceState'
import DepositState from '@/product/context/deposit/depositState'
import AuctionState from '@/product/context/auction/auctionState'
import CreditState from '@/product/context/credit/creditState'
import OfflineState from '@/product/context/offline/offlineState'
import CartState from '@/product/context/cart/cartState'
import BuyerState from '@/product/context/buyer/buyerState'
import AutopltState from '@/product/context/autopilot/autopltState'
import PayState from '@/product/context/payment/payState'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import ArtaShippingState from './product/context/shipping/arta/artaState'
import DynamicState from './product/context/dynamic/dynamicState'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import Alerts from '@/product/common/alert'
import BuynowState from '@/product/context/buynow/buynowState'
import AppointmentState from '@/product/context/appointment/appointmentState'
import RosoomState from '@/product/context/payment/rosoom/rosoomState'
import StripeCardState from './product/context/stripe/card/cardState'
import StripeCustomerState from './product/context/stripe/customer/customerState'
import StripeBankState from './product/context/stripe/bank/bankState'
import SellerProductState from './product/context/seller/product/productState'
import SellerSettingState from './product/context/seller/setting/settingState'
import ShipengineState from './product/context/shipping/shipengine/shipengineState'
import SellerAuctionState from './product/context/seller/auction/auctionState'
import TemplateProductState from './product/context/seller/template/product/productState'
import TemplateAuctionState from './product/context/seller/template/auction/auctionState'
import UserStateseller from './product/context/seller/user/userState'
import SellerReportState from './product/context/seller/report/reportState'
import ParticipationPackState from './custom/context/participationPack/participationPackState'
import CustomProfileState from './custom/context/profile/profileState'

import ShipstationState from './product/context/shipping/shipstation/shipstationState'
import i18n from '@/product/common/language-i18n'
import withRoot from './withRoot'
import { create } from 'jss'
import rtl from 'jss-rtl'
import { Helmet } from 'react-helmet'
import SEO from '@/utils/SEO'
import {
    MuiThemeProvider,
    StylesProvider,
    createMuiTheme,
    jssPreset,
    useTheme,
} from '@material-ui/core/styles'
import CustomCommonContext from './custom/context/common/commonContext'
import HeadAlert from './custom/components/molecules/HeadAlert'
import { setIPAddress } from './product/common/api'
import publicIp from 'public-ip'
import BidState from '@/product/context/reverse/bid/bidState'
import AlbumState from '@/product/context/album/albumState'

global.site_url = process.env.REACT_APP_DOMAIN
global.images_url = global.site_url + '/uploads/product/'
global.ignoreStates = []
global.ignoreCountry = []
global.defaultCountry = 'US'

const App = () => {
    const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
    // const customCommonContext = useContext(CustomCommonContext)
    // const { currentLanguage } = customCommonContext

    const [currLang, setCurrLang] = useState()
    const theme = useTheme()
    const ltrTheme = createMuiTheme({ direction: 'ltr' })
    const rtlTheme = createMuiTheme({ direction: 'rtl' })
    useEffect(() => {
        // localStorage.removeItem('currLanguage')
        // localStorage.setItem('currLanguage', document.body.dir)
        // setCurrLang(`${localStorage.i18nextLng == 'for_english' ? 'ltr' : 'rtl'}`)
        // setCurrLang(`${localStorage.i18nextLng == 'for_english' ? 'ltr' : 'rtl'}`)
        // console.log(localStorage.currLanguage, localStorage.i18nextLng, currLang, 'i18n.language')

        // localStorage.i18nextLng == 'for_english'
        //     ? (theme.direction = 'ltr')
        //     : (theme.direction = 'rtl')

        setCurrLang(localStorage.currLanguage == 'for_english' ? rtlTheme : ltrTheme)

        // console.log(theme.direction, 'theme.direction')
    }, [localStorage.currLanguage])

    useEffect(() => {
        async function fetchData() {
            const ipaddress = await publicIp.v4()
            setIPAddress(ipaddress)
        }
        fetchData()
    }, [])

    return (
        <>
            <SEO url={window.location.href} />
            <CookiesProvider>
                <StylesProvider jss={jss}>
                    <I18nextProvider i18n={i18n}>
                        <CustomCommonState>
                            {/* <StylesProvider jss={jss}>
                        <MuiThemeProvider
                            theme={createMuiTheme({
                                direction: currLang,
                            })}
                        > */}
                            <ProductCommonState>
                                <AuthState>
                                    <AlertState>
                                        <UserState>
                                            <UserStateseller>
                                                <StripeCardState>
                                                    <StripeCustomerState>
                                                        <StripeBankState>
                                                            <ProductState>
                                                                <AuctionState>
                                                                    <BuynowState>
                                                                        <CartState>
                                                                            <AppointmentState>
                                                                                <CreditState>
                                                                                    <RosoomState>
                                                                                        <PayState>
                                                                                            <SellerReportState>
                                                                                                <SellerSettingState>
                                                                                                    <OfflineState>
                                                                                                        <InvoiceState>
                                                                                                            <DepositState>
                                                                                                                <BuyerState>
                                                                                                                    <AutopltState>
                                                                                                                        <ParticipationPackState>
                                                                                                                            <CustomProfileState>
                                                                                                                                <ArtaShippingState>
                                                                                                                                    <ShipengineState>
                                                                                                                                        <SellerProductState>
                                                                                                                                            <SellerAuctionState>
                                                                                                                                                <AlbumState>
                                                                                                                                                    <TemplateProductState>
                                                                                                                                                        <TemplateAuctionState>
                                                                                                                                                            <ShipstationState>
                                                                                                                                                                <BidState>
                                                                                                                                                                    <DynamicState>
                                                                                                                                                                        <MuiPickersUtilsProvider
                                                                                                                                                                            utils={
                                                                                                                                                                                MomentUtils
                                                                                                                                                                            }
                                                                                                                                                                        >
                                                                                                                                                                            <SnackbarProvider
                                                                                                                                                                                maxSnack={
                                                                                                                                                                                    3
                                                                                                                                                                                }
                                                                                                                                                                            >
                                                                                                                                                                                <div className="App">
                                                                                                                                                                                    <Alerts />
                                                                                                                                                                                    <HeadAlert />
                                                                                                                                                                                    <Router>
                                                                                                                                                                                        <Routes />
                                                                                                                                                                                    </Router>
                                                                                                                                                                                </div>
                                                                                                                                                                            </SnackbarProvider>
                                                                                                                                                                        </MuiPickersUtilsProvider>
                                                                                                                                                                    </DynamicState>
                                                                                                                                                                </BidState>
                                                                                                                                                            </ShipstationState>
                                                                                                                                                        </TemplateAuctionState>
                                                                                                                                                    </TemplateProductState>
                                                                                                                                                </AlbumState>
                                                                                                                                            </SellerAuctionState>
                                                                                                                                        </SellerProductState>
                                                                                                                                    </ShipengineState>
                                                                                                                                </ArtaShippingState>
                                                                                                                            </CustomProfileState>
                                                                                                                        </ParticipationPackState>
                                                                                                                    </AutopltState>
                                                                                                                </BuyerState>
                                                                                                            </DepositState>
                                                                                                        </InvoiceState>
                                                                                                    </OfflineState>
                                                                                                </SellerSettingState>
                                                                                            </SellerReportState>
                                                                                        </PayState>
                                                                                    </RosoomState>
                                                                                </CreditState>
                                                                            </AppointmentState>
                                                                        </CartState>
                                                                    </BuynowState>
                                                                </AuctionState>
                                                            </ProductState>
                                                        </StripeBankState>
                                                    </StripeCustomerState>
                                                </StripeCardState>
                                            </UserStateseller>
                                        </UserState>
                                    </AlertState>
                                </AuthState>
                            </ProductCommonState>
                            {/* </MuiThemeProvider>
                    </StylesProvider> */}
                        </CustomCommonState>
                    </I18nextProvider>
                </StylesProvider>
            </CookiesProvider>
        </>
    )
}

export default withRoot(App)
