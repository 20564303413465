import {
    GET_ALL_PACKS,
    GET_SINGLE_PACK,
    GET_ALL_CALENDARS,
    GET_SINGLE_CALENDAR,
    GET_ALL_PACK_FILES,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './participationPackTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_PACKS:
            return {
                ...state,
                all_packs: action.payload,
            }
        case GET_SINGLE_PACK:
            return {
                ...state,
                single_pack: action.payload,
            }
        case GET_ALL_PACK_FILES:
            return {
                ...state,
                all_packs_files: action.payload,
            }

        case GET_ALL_CALENDARS:
            return {
                ...state,
                all_calendars: action.payload,
            }
        case GET_SINGLE_CALENDAR:
            return {
                ...state,
                single_calendar: action.payload,
            }

        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
