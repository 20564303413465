import React, { useReducer } from 'react'
import ParticipationPackContext from './participationPackContext'
import ParticipationPackReducer from './participationPackReducer'
import { apiCall } from '@/product/common/api'
import { response } from '@/product/context/common'

import {
    GET_ALL_PACKS,
    GET_SINGLE_PACK,
    GET_ALL_CALENDARS,
    GET_SINGLE_CALENDAR,
    GET_ALL_PACK_FILES,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './participationPackTypes'

const ParticipationPackState = (props) => {
    const initialState = {
        single_pack: {
            record: {},
        },
        all_packs: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            from: '',
        },
        all_packs_files: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            from: '',
        },
        single_calendar: {
            record: {},
        },
        all_calendars: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            from: '',
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(ParticipationPackReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllPacks = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'searchPack', formData, '', 'front/participation', true),
            ])
            const from = fromVariable ? fromVariable : 'Auctions'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_PACKS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSinglePack = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSinglePack', formData, '', 'front/participation', true),
            ])

            const from = fromVariable || 'getSinglePack'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_PACK,
                    payload: {
                        record: res.data.data.responseData.record
                            ? res.data.data.responseData.record
                            : {},
                        from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllPacksFiles = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'searchPackFiles', formData, '', 'front/participation', true),
            ])
            const from = fromVariable ? fromVariable : 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_PACK_FILES,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllCalendars = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'searchCalendar', formData, '', 'front/participation', true),
            ])
            const from = fromVariable ? fromVariable : 'Auctions'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_CALENDARS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleCalendar = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSingleCalendar', formData, '', 'front/participation', true),
            ])

            const from = fromVariable || 'getSingleCalendar'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_CALENDAR,
                    payload: {
                        record: res.data.data.responseData.record
                            ? res.data.data.responseData.record
                            : {},
                        from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <ParticipationPackContext.Provider
            value={{
                responseStatus: state.responseStatus,
                all_packs: state.all_packs,
                all_packs_files: state.all_packs_files,
                single_pack: state.single_pack,
                single_calendar: state.single_calendar,
                all_calendars: state.all_calendars,
                clearResponse,
                getAllPacks,
                getSinglePack,
                getAllCalendars,
                getSingleCalendar,
                getAllPacksFiles,
            }}
        >
            {props.children}
        </ParticipationPackContext.Provider>
    )
}

export default ParticipationPackState
