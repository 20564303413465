import React, { useReducer } from 'react'
import ProfileContext from './profileContext'
import ProfileReducer from './profileReducer'
import { apiCall } from '@/product/common/api'
import { response } from '@/product/context/common'

import { CLEAR_RESPONSE, RESPONSE_STATUS } from './profileTypes'

const ProfileState = (props) => {
    const initialState = {
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(ProfileReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const updateProfileChange = async (formData, noAlert, from) => {
        from = from ? from : 'updateProfileChange'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateProfileChange', formData, '', 'front/profile', true),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <ProfileContext.Provider
            value={{
                responseStatus: state.responseStatus,
                clearResponse,
                updateProfileChange,
            }}
        >
            {props.children}
        </ProfileContext.Provider>
    )
}

export default ProfileState
