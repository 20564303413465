import React, { useState, useContext, useEffect, useRef } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { LOGO, SITE_NAME } from '@/utils'
import { Link } from 'react-router-dom'
import {
    Badge,
    Button,
    Divider,
    ListItem,
    Menu,
    MenuItem,
    SwipeableDrawer,
} from '@material-ui/core'
import AuthContext from '@/product/context/auth/authContext'
import CommonContext from '@/product/context/common/commonContext'
import CustomCommonContext from '@/custom/context/common/commonContext'
import AlertContext from '@/product/context/alert/alertContext'
import { handleRedirectInternal, useCustomMediaQuery } from '@/product/common/components'
import './Header.css'
import BidHistory from '@/custom/components/organisms/BidHistory'
import BuynowContext from '@/product/context/buynow/buynowContext'
import Loaders from '../Loaders'
import { Popover } from '@material-ui/core'
import { mapData } from '@/product/common/components'
import { LOGO_BLUE } from '@/utils/index'
import CustomDialog from '@/custom/components/organisms/Dialog'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import * as Yup from 'yup'
import CustomProfileContext from '@/custom/context/profile/profileContext'
import UserContext from '@/product/context/user/userContext'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useFormik } from 'formik'
import SecondaryButton from '@/product/components/atoms/SecondaryButton'
import { socket } from '@/product/common/socket'
import ReactPlayer from 'react-player'

const Header = () => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const customCommonContext = useContext(CustomCommonContext)
    const buynowContext = useContext(BuynowContext)

    const customProfileContext = useContext(CustomProfileContext)
    const { updateProfileChange, responseStatus: responseStatusProfile } = customProfileContext

    const userContext = useContext(UserContext)

    const { updateProfile, updatePreference, responseStatus } = userContext

    const { setAlert } = alertContext
    const {
        user,
        isAuthenticated,
        loadPendingCount,
        isAdmin,
        cartCount,
        logout,
        switchUser,
        login,
        responseStatus: responseStatusAuth,
        clearResponse: clearResponseAuth,
    } = authContext

    const [state, setState] = useState({
        right: false,
        top: false,
    })

    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const [anchorcart, setanchorcart] = useState(null)
    const [played, setPlayed] = useState(false)

    const { getAllCartProducts, search_allproducts } = buynowContext

    const { searchValue, setSearchValue, static_page, getStaticPage, isLoading } = commonContext
    const { socketModal } = customCommonContext
    const [searchText, setSearchText] = useState('')
    let [cartProducts, setCartProducts] = useState([])
    const [search, setSearch] = useState({})

    useEffect(() => {
        if (isAuthenticated) {
            if (user) {
                if (user.force_reset_pass) {
                    handleRedirectInternal(history, 'reset_password')
                }
            }
        }
    }, [user, isAuthenticated])

    const audioRef = useRef(null)

    useEffect(() => {
        // Preload the audio file
        audioRef.current = new Audio('/assets/outbid.mp3')
        audioRef.current.load()
    }, [])

    const toggleSound = () => {
        // Play the sound on user interaction
        if (audioRef.current) {
            audioRef.current.play().catch((error) => {
                console.error('Error playing the sound:', error)
            })
        }
    }

    // const toggleSound = () => {
    //     setPlayed(true)

    //     setTimeout(() => {
    //         setPlayed(false)
    //     }, '3000')
    // }

    useEffect(() => {
        if (isAuthenticated) {
            getAllCartProducts(search)
        }
    }, [isAuthenticated])

    useEffect(() => {
        setCartProducts(search_allproducts.cartrecords.length ? search_allproducts.cartrecords : [])
    }, [search_allproducts])

    useEffect(() => {
        setCartProducts([])
        return () => {
            setCartProducts([])
        }
    }, [])

    useEffect(() => {
        if (searchValue) {
            handleRedirectInternal(history, `search`)
        }
    }, [searchValue])

    const onLogOut = () => {
        logout()
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
        setanchormenu(null)
        setanchorcart(null)
        setSearchText('')
        setLogoutModal(false)
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const handleCart = (event) => {
        setanchorcart(event.currentTarget)
    }
    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        setanchorcart(null)
        if (history && path && path !== 'backdropClick') {
            console.log('history', history, path)
            handleRedirectInternal(history, path)
        }
    }

    const getSearchBarObjet = (value) => {
        return {
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                searchbar: {
                    value: value,
                    type: 'like',
                    field: 'p.title,p.desc_proc',
                },
            },
        }
    }

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleSearchButtonClick = () => {
        setSearchValue(getSearchBarObjet(searchText))
        setState({ ...state, top: false })
        if (location.pathname != '/search') {
            handleRedirectInternal(history, 'search')
        }
    }

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            setSearchValue(getSearchBarObjet(searchText))
            if (location.pathname != '/search') {
                handleRedirectInternal(history, 'search')
            }
        }
    }

    useEffect(() => {
        if (searchValue && searchValue.filters && searchValue.filters.searchbar) {
            setSearchText(searchValue.filters.searchbar.value)
        }
    }, [searchValue])

    useEffect(() => {
        if (isAuthenticated) {
            let auctionFilter = {
                table: 'buynow',
                field: 'id',
                extraField: '',
                filters: {
                    buynow_autype: {
                        value: '',
                        type: 'in',
                        field: 'b.buynow_autype',
                    },
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'b.paid',
                    },
                    partial: {
                        value: '0',
                        type: 'in',
                        field: 'b.partial',
                    },
                    active: {
                        value: '2',
                        type: 'notin',
                        field: 'b.active',
                    },
                },
            }
            let buyNowFilter = {
                table: 'cart_temp',
                field: 'cart_id',
                extraField: '',
                filters: {
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'crt.paid',
                    },
                },
            }

            loadPendingCount(buyNowFilter, 'buynowCount')

            loadPendingCount(auctionFilter, 'auctionCount')
        }
    }, [isAuthenticated, history.location.pathname])

    const [count, setCount] = useState({
        auctionCount: 0,
        buynowCount: 0,
    })

    useEffect(() => {
        if (cartCount?.auctionCount) {
            setCount({ ...count, auctionCount: cartCount.auctionCount.totalRecords })
        }
        if (cartCount?.buynowCount) {
            setCount({ ...count, buynowCount: cartCount.buynowCount.totalRecords })
        }
    }, [cartCount])

    const [logoutModal, setLogoutModal] = useState(false)
    const manageLogoutModal = () => {
        setLogoutModal(!logoutModal)
        setanchormenu(null)
    }

    const [toggleProfileDialog, setToggleProfileDialog] = useState(false)
    const changeProfileDialogStatus = () => {
        setToggleProfileDialog(!toggleProfileDialog)
    }

    const [toggleDisconnectDialog, setToggleDisconnectDialog] = useState(false)
    const [socketDisconnect, setSocketDisconnect] = useState(false)

    const changeDisconnectDialogStatus = () => {
        setToggleDisconnectDialog(!toggleDisconnectDialog)
    }

    const [toggleDialog, setToggleDialog] = useState(false)
    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const formikDetails = useFormik({
        initialValues: {
            profilecheck: 1,
            profilechange: 0,
        },
        onSubmit: (values) => {
            updateProfileChange(values)
            changeProfileDialogStatus()
        },
    })

    const validationArray = Yup.object({
        termcheck: Yup.boolean().oneOf([true], 'You need to accept the terms and conditions'),
    })

    const formikProfile = useFormik({
        initialValues: {
            termcheck: 0,
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            updateProfile(values)
            changeDialogStatus()
        },
    })

    const TermsBidding = {
        formik: formikProfile,
        data: [
            {
                label: (
                    <>
                        {'I agree to the '}&nbsp;
                        <Link to="/terms_of_use" target="_blank">
                            {' '}
                            Terms & Conditions
                        </Link>
                    </>
                ),
                name: 'termcheck',
                type: 'checkbox',
                placeholder: '',
                class: 'col-12 auth-links-wrap termsCndtn',
            },
        ],
    }

    useEffect(() => {
        if (user && isAuthenticated) {
            if (user.profilecheck) {
                setToggleProfileDialog(false)
            } else {
                if (
                    location.pathname != '/terms_of_use' &&
                    location.pathname != '/reset_password'
                ) {
                    setToggleProfileDialog(true)
                }
            }
        }
    }, [user, isAuthenticated])

    useEffect(() => {
        if (user && isAuthenticated) {
            if (user.termcheck) {
                setToggleDialog(false)
                // if (user.profilecheck) {
                //     setToggleProfileDialog(false)
                // } else {
                //     setToggleProfileDialog(true)
                // }
            } else {
                if (
                    location.pathname != '/terms_of_use' &&
                    location.pathname != '/reset_password'
                ) {
                    getStaticPage({ id: 'terms_of_use' })
                    setToggleDialog(true)
                }
            }
        }
    }, [user, isAuthenticated])
    let [termsContent, setTermsContent] = useState(null)
    useEffect(() => {
        console.log('static_page', static_page)
        setTermsContent(static_page?.record?.content ? static_page.record.content : null)
    }, [static_page])

    function refreshPage() {
        window.location.reload(false)
    }

    const [isMobile, setIsMobile] = useState(false)
    const [userData, setUserData] = useState(null)
    const [isSocketDisconnected, setIsSocketDisconnected] = useState(false)
    const isMobileRef = useRef(isMobile)
    const isSocketDisconnectedRef = useRef(isSocketDisconnected)
    const userDataRef = useRef(userData)

    useEffect(() => {
        isMobileRef.current = isMobile
        isSocketDisconnectedRef.current = isSocketDisconnected
        userDataRef.current = userData
    })
    useEffect(() => {
        setUserData(user)
    }, [user])

    useEffect(() => {
        const checkIfMobile = () => {
            // Check using window.innerWidth
            const isMobileDevice = window.innerWidth <= 1024 // You can adjust this threshold as needed
            setIsMobile(isMobileDevice)

            // Alternatively, you can use window.matchMedia
            // const isMobileDevice = window.matchMedia('(max-width: 768px)').matches;
            // setIsMobile(isMobileDevice);
        }

        // Initial check
        checkIfMobile()

        // Add event listener for window resize
        window.addEventListener('resize', checkIfMobile)

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', checkIfMobile)
        }
    }, []) // Empty dependency array ensures useEffect runs only once on component mount

    const connecthandle = () => {
        console.log('isMobileRef.current', isMobileRef.current)
        setSocketDisconnect(false)
        if (isMobileRef.current && isSocketDisconnectedRef.current) {
            window.location.reload()
        }
    }
    const disconnecthandle = () => {
        console.log('isMobileRef.current', isMobileRef.current)
        if (isMobileRef.current) {
            setSocketDisconnect(true)
            setIsSocketDisconnected(true)
        } else {
            setToggleDisconnectDialog(true)
        }
    }

    const biddinghandle = (data) => {
        console.log(' userDataRef.current')
        console.log('bpop_outbid_userid', data.bpop_outbid_userid)
        if (userDataRef.current) {
            if (data.bpop_outbid_userid) {
                if (userDataRef.current.id) {
                    if (
                        parseInt(userDataRef.current.id, 10) ===
                        parseInt(data.bpop_outbid_userid, 10)
                    ) {
                        if (userDataRef.current.sound_outbid) {
                            // Play sound here
                            toggleSound()
                        }
                    }
                }
            }
        }
    }
    useEffect(() => {
        if (socket) {
            socket.on('connect', () => {
                console.log('isMobileRef 2222 CONNECTED')
                connecthandle()
            })
            socket.on('disconnect', (data) => {
                console.log('isMobileRef 2222 333333', data)
                if (data !== 'io client disconnect') {
                    disconnecthandle()
                }
            })
            socket.on('bidAddtime', (data) => {
                console.log('socket 3333 bidAddtime')
                biddinghandle(data)
            })
        }
    }, [socket])

    // useEffect(() => {
    //     if (socketModal) {
    //         setToggleDisconnectDialog(true)
    //     }
    // }, [socketModal])

    const [expanded, setExpanded] = useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const handleReady = (error) => {
        console.log('handleReady', error)
    }

    return (
        <>
            {useCustomMediaQuery('(min-width: 1025px)') ? (
                <>
                    {/* DESKTOP NAVIGATION */}
                    <Loaders name="home" isLoading={isLoading} />
                    <header className="mainHeader noPrint">
                        <div
                            className={`${'customContainer'} d-flex justify-content-between align-items-center h-100`}
                        >
                            <div className="headLt d-flex justify-content-start align-items-center">
                                <Link to="/" className="ASlogo" id="debeers_logo">
                                    <img src={LOGO} alt={SITE_NAME} width="200" height="40" />
                                </Link>
                                {/* <div className="form-inline my-2 my-lg-0 headerSearchForm">
                                    <input
                                        className="form-control"
                                        type="search"
                                        placeholder={'Search auction'}
                                        aria-label="Search"
                                        value={searchText}
                                        onChange={handleSearchTextChange}
                                        onKeyDown={handleSearchKeyDown}
                                    />
                                    <Button className="btn" onClick={handleSearchButtonClick}>
                                        <span className="material-icons">search</span>
                                    </Button>
                                </div> */}
                            </div>
                            <div className="menuRtOtr">
                                <div className="headRt deskNav d-flex justify-content-end align-items-center">
                                    <ul className="d-flex justify-content-start align-items-center">
                                        <li>
                                            <NavLink
                                                activeClassName="active"
                                                id="header_auction"
                                                to="/calendars"
                                            >
                                                Auction Calendar
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink
                                                activeClassName="active"
                                                id="header_auction"
                                                to="/packs"
                                                isActive={(match, location) => {
                                                    return (
                                                        location.pathname === '/packs' ||
                                                        location.pathname.startsWith('/pack/')
                                                    )
                                                }}
                                            >
                                                Participation Pack
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink
                                                activeClassName="active"
                                                id="header_auction"
                                                to="/albums"
                                                isActive={(match, location) => {
                                                    return (
                                                        location.pathname === '/albums' ||
                                                        location.pathname.startsWith('/album/')
                                                    )
                                                }}
                                            >
                                                Albums
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink
                                                activeClassName="active"
                                                id="header_auction"
                                                to="/auction"
                                            >
                                                Auctions
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                activeClassName="active"
                                                id="header_auction"
                                                to="/offer"
                                            >
                                                Offers
                                            </NavLink>
                                        </li>
                                        {/* <li>
                                            <NavLink activeClassName="active" to="/search">
                                                Search
                                            </NavLink>
                                        </li> */}

                                        {/* <li>
                                            <NavLink activeClassName="active" to="/about">
                                                About Us
                                            </NavLink>
                                        </li> */}

                                        {/* <li>
                                            {isAuthenticated ? (
                                                <>
                                                    <Button
                                                        aria-controls="simple-cart"
                                                        aria-haspopup="true"
                                                        onClick={handleCart}
                                                    >
                                                        <Badge
                                                            badgeContent={
                                                                count.buynowCount +
                                                                count.auctionCount
                                                            }
                                                            color="primary"
                                                        >
                                                            <span className="material-icons">
                                                                shopping_cart
                                                            </span>
                                                        </Badge>
                                                    </Button>
                                                    <Popover
                                                        anchorEl={anchorcart}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        id="simple-cart"
                                                        keepMounted
                                                        class={`headerDropMenu ${
                                                            document.body.dir === 'rtl' && 'rtl'
                                                        }`}
                                                        open={Boolean(anchorcart)}
                                                        onClose={handleClose}
                                                    >
                                                        <ul
                                                            className={`headerDropMenu ${
                                                                document.body.dir === 'rtl' && 'rtl'
                                                            }`}
                                                        >
                                                           <ListItem
                                                                button
                                                                onClick={() =>
                                                                    handleClose(
                                                                        history,
                                                                        'buynowcart',
                                                                    )
                                                                }
                                                            >
                                                                <Badge
                                                                    badgeContent={count.buynowCount}
                                                                    color="primary"
                                                                >
                                                                    <span className="material-icons">
                                                                        shopping_bag
                                                                    </span>
                                                                </Badge>
                                                                {'Buy now cart'}
                                                            </ListItem> 
                                                            <ListItem
                                                                button
                                                                onClick={() =>
                                                                    handleClose(history, 'cart')
                                                                }
                                                            >
                                                                <Badge
                                                                    badgeContent={
                                                                        count.auctionCount
                                                                    }
                                                                    color="primary"
                                                                >
                                                                    <span className="material-icons">
                                                                        gavel
                                                                    </span>
                                                                </Badge>

                                                                {'Auction cart'}
                                                            </ListItem>
                                                        </ul>
                                                    </Popover>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </li> */}
                                        {isAuthenticated ? (
                                            <>
                                                <>
                                                    {' '}
                                                    <li>
                                                        <Button
                                                            aria-controls="simple-menu"
                                                            aria-haspopup="true"
                                                            className=""
                                                            onClick={handleMenu}
                                                            id="header_shortname"
                                                        >
                                                            {/* <span className="">
                                                                {user?.first_name?.charAt(0)}
                                                                {user?.last_name?.charAt(0)}
                                                            </span> */}
                                                            My Dashboard
                                                            <span className="material-icons">
                                                                {anchormenu
                                                                    ? 'expand_less'
                                                                    : 'expand_more'}
                                                            </span>
                                                        </Button>

                                                        <Popover
                                                            id={'simple-menu'}
                                                            open={Boolean(anchormenu)}
                                                            anchorEl={anchormenu}
                                                            onClose={handleClose}
                                                            className="headerMenuList"
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >
                                                            <ul
                                                                className={`headerDropMenu ${
                                                                    document.body.dir === 'rtl' &&
                                                                    'rtl'
                                                                }`}
                                                            >
                                                                <ListItem
                                                                    button
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            history,
                                                                            'account/profile',
                                                                        )
                                                                    }
                                                                    id="header_myaccount"
                                                                >
                                                                    <span className="material-icons">
                                                                        person
                                                                    </span>
                                                                    My Account
                                                                </ListItem>
                                                                <ListItem
                                                                    button
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            history,
                                                                            'auctions/live/all',
                                                                        )
                                                                    }
                                                                    id="header_mybids"
                                                                >
                                                                    <span className="material-icons">
                                                                        gavel
                                                                    </span>
                                                                    My Bids
                                                                </ListItem>

                                                                {/* <ListItem
                                                                    button
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            history,
                                                                            'offers/live/all',
                                                                        )
                                                                    }
                                                                    id="header_myoffers"
                                                                >
                                                                    <span className="material-icons">
                                                                        assignment_add
                                                                    </span>
                                                                    My Offers
                                                                </ListItem> */}

                                                                <ListItem
                                                                    button
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            history,
                                                                            'watchlist/watchlist',
                                                                        )
                                                                    }
                                                                    id="header_watchlist"
                                                                >
                                                                    <span className="material-icons">
                                                                        favorite
                                                                    </span>
                                                                    My Watchlist
                                                                </ListItem>

                                                                {/* <ListItem
                                                                    button
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            history,
                                                                            'saved_search',
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="material-icons">
                                                                        bookmark
                                                                    </span>
                                                                    My Saved Searches
                                                                </ListItem> */}

                                                                <ListItem
                                                                    button
                                                                    onClick={manageLogoutModal}
                                                                    id="header_logout"
                                                                >
                                                                    <span className="material-icons">
                                                                        power_settings_new
                                                                    </span>
                                                                    Logout
                                                                </ListItem>
                                                            </ul>
                                                        </Popover>
                                                    </li>
                                                </>
                                            </>
                                        ) : (
                                            <>
                                                <li>
                                                    <NavLink
                                                        to="/login"
                                                        id="header_login"
                                                        ActiveclassName="active"
                                                    >
                                                        Login
                                                    </NavLink>
                                                </li>
                                                {/* <li>
                                                    <NavLink
                                                        to="/registration"
                                                        ActiveclassName="active"
                                                    >
                                                        Register
                                                    </NavLink>
                                                </li> */}
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>
                </>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="mainHeader customContainer d-flex justify-content-between align-items-center">
                        <div className="headLt d-flex justify-content-start">
                            <Link to="/">
                                <img src={LOGO} alt={SITE_NAME} width="130" height="25" />
                            </Link>
                        </div>
                        <div className="headRt">
                            <ul className="d-flex justify-content-start ml-auto align-items-center">
                                {/* <li>
                                    <Button
                                        onClick={() => handleRedirectInternal(history, 'search')}
                                    >
                                        <span className="material-icons">search</span>
                                    </Button>
                                </li> */}

                                <li>
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('right', true)}
                                    >
                                        <span className="material-icons">menu</span>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </header>
                    {/* <React.Fragment>
                        <SwipeableDrawer
                            className="respHeaderSearch"
                            anchor={'top'}
                            open={state['top']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('top', false)}
                            onOpen={toggleDrawer('top', true)}
                        >
                            <div className="form-inline my-2 my-lg-0 headerSearchForm">
                                <input
                                    className="form-control"
                                    type="search"
                                    placeholder="search"
                                    aria-label="Search"
                                    value={searchText}
                                    onChange={handleSearchTextChange}
                                    onKeyDown={handleSearchKeyDown}
                                />
                                <Button className="btn" onClick={handleSearchButtonClick}>
                                    <span className="material-icons">search</span>
                                </Button>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment> */}
                    {socketDisconnect && (
                        <p className="network-issue">
                            Network has been disconnected trying to reconnect... or click here to{' '}
                            <span
                                onClick={() => window.location.reload(0)}
                                className="network-reload"
                            >
                                reload
                            </span>
                        </p>
                    )}
                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/">
                                        <img src={LOGO_BLUE} alt={SITE_NAME} height="60" />
                                    </Link>
                                    <Button
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />

                                <ul
                                    className="navRespLinks mt-0"
                                    // onClick={toggleDrawer('right', false)}
                                >
                                    <ListItem button onClick={toggleDrawer('right', false)}>
                                        <NavLink
                                            activeClassName="active"
                                            id="header_auctions"
                                            to={'/auction'}
                                        >
                                            <span className="material-icons">live_tv</span>
                                            Auctions
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button onClick={toggleDrawer('right', false)}>
                                        <NavLink
                                            activeClassName="active"
                                            id="header_offers"
                                            to={'/offer'}
                                        >
                                            <span className="material-icons-outlined">
                                                local_offer
                                            </span>
                                            Offers
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button onClick={toggleDrawer('right', false)}>
                                        <NavLink
                                            activeClassName="active"
                                            id="header_offers"
                                            to={'/albums'}
                                        >
                                            <span className="material-icons-outlined">
                                                photo_library
                                            </span>
                                            Albums
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button onClick={toggleDrawer('right', false)}>
                                        <NavLink
                                            activeClassName="active"
                                            id="header_offers"
                                            to={'/packs'}
                                        >
                                            <span className="material-icons-outlined">
                                                description
                                            </span>
                                            Participation Pack
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button onClick={toggleDrawer('right', false)}>
                                        <NavLink
                                            activeClassName="active"
                                            id="header_offers"
                                            to={'/calendars'}
                                        >
                                            <span className="material-icons-outlined">
                                                calendar_month
                                            </span>
                                            Auction Calendar
                                        </NavLink>
                                    </ListItem>

                                    {isAuthenticated ? (
                                        <>
                                            <Accordion
                                                expanded={expanded === 'panel1'}
                                                onChange={handleChange('panel1')}
                                                className="headerMenuAccordian"
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography>
                                                        {' '}
                                                        <span className="material-icons-outlined">
                                                            manage_accounts
                                                        </span>{' '}
                                                        My Account
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div>
                                                        <ListItem
                                                            button
                                                            onClick={toggleDrawer('right', false)}
                                                        >
                                                            <NavLink
                                                                activeClassName="active"
                                                                to={'/account/profile'}
                                                            >
                                                                {/* <span className="material-icons-outlined">
                                                                    person
                                                                </span> */}
                                                                Profile
                                                            </NavLink>
                                                        </ListItem>
                                                        <ListItem
                                                            button
                                                            onClick={toggleDrawer('right', false)}
                                                        >
                                                            <NavLink
                                                                activeClassName="active"
                                                                to={'/account/change'}
                                                            >
                                                                {/* <span className="material-icons-outlined">
                                                                    password
                                                                </span> */}
                                                                Change Password
                                                            </NavLink>
                                                        </ListItem>
                                                        <ListItem
                                                            button
                                                            onClick={toggleDrawer('right', false)}
                                                        >
                                                            <NavLink
                                                                activeClassName="active"
                                                                to={'/account/notifications'}
                                                            >
                                                                {/* <span className="material-icons-outlined">
                                                                    notifications
                                                                </span> */}
                                                                My Notifications
                                                            </NavLink>
                                                        </ListItem>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>

                                            <Accordion
                                                expanded={expanded === 'panel2'}
                                                onChange={handleChange('panel2')}
                                                className="headerMenuAccordian"
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2bh-content"
                                                    id="panel2bh-header"
                                                >
                                                    <Typography>
                                                        {' '}
                                                        <span className="material-icons-outlined">
                                                            gavel
                                                        </span>{' '}
                                                        My Bids
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div>
                                                        <ListItem
                                                            button
                                                            onClick={toggleDrawer('right', false)}
                                                        >
                                                            <NavLink
                                                                activeClassName="active"
                                                                to={'/auctions/live/all'}
                                                            >
                                                                {/* <span className="material-icons-outlined">
                                                                    person
                                                                </span> */}
                                                                Auctions Live
                                                            </NavLink>
                                                        </ListItem>
                                                        <ListItem
                                                            button
                                                            onClick={toggleDrawer('right', false)}
                                                        >
                                                            <NavLink
                                                                activeClassName="active"
                                                                to={'/auctions/closed/all'}
                                                            >
                                                                {/* <span className="material-icons-outlined">
                                                                    password
                                                                </span> */}
                                                                Auctions Closed
                                                            </NavLink>
                                                        </ListItem>
                                                        <ListItem
                                                            button
                                                            onClick={toggleDrawer('right', false)}
                                                        >
                                                            <NavLink
                                                                activeClassName="active"
                                                                to={'/offers/live/all'}
                                                            >
                                                                {/* <span className="material-icons-outlined">
                                                                    notifications
                                                                </span> */}
                                                                Offers Live
                                                            </NavLink>
                                                        </ListItem>
                                                        <ListItem
                                                            button
                                                            onClick={toggleDrawer('right', false)}
                                                        >
                                                            <NavLink
                                                                activeClassName="active"
                                                                to={'/offers/closed/all'}
                                                            >
                                                                {/* <span className="material-icons-outlined">
                                                                    notifications
                                                                </span> */}
                                                                Offers Closed
                                                            </NavLink>
                                                        </ListItem>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>

                                            {/* <ListItem button onClick={toggleDrawer('right', false)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/auctions/all"
                                                >
                                                    <span className="material-icons">gavel</span>
                                                    My Bids
                                                </NavLink>
                                            </ListItem> */}
                                            {/* <ListItem button onClick={toggleDrawer('right', false)}>
                                                <NavLink activeClassName="active" to="/offers/open">
                                                    <span className="material-icons">
                                                        assignment_add
                                                    </span>
                                                    My Offers
                                                </NavLink>
                                            </ListItem> */}

                                            <ListItem button onClick={toggleDrawer('right', false)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/watchlist/watchlist"
                                                >
                                                    <span className="material-icons">favorite</span>
                                                    My Watchlist
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    ) : (
                                        <>
                                            <ListItem button onClick={toggleDrawer('right', false)}>
                                                <NavLink
                                                    to="/login"
                                                    ActiveclassName="active"
                                                    onClose={toggleDrawer('right', false)}
                                                    id="header_login"
                                                >
                                                    <span className="material-icons">login</span>
                                                    Login
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )}

                                    {/* <ListItem button>
                                        <NavLink
                                            ActiveclassName="active"
                                            to="/about"
                                            id="header_about"
                                        >
                                            <span className="material-icons-outlined">info</span>
                                            About
                                        </NavLink>
                                    </ListItem> */}
                                    {isAuthenticated && (
                                        <ListItem className="" button onClick={manageLogoutModal}>
                                            <span className="material-icons">
                                                power_settings_new
                                            </span>
                                            Logout
                                        </ListItem>
                                    )}
                                </ul>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </>
            )}

            {/* <ReactPlayer
                url="/assets/outbid.mp3"
                playing={played}
                loop={false}
                onError={handleReady}
                width={'0px'}
                height={'0px'}
            /> */}
            <BidHistory />

            <CustomDialog
                title={'Logout Confirmation'}
                open={logoutModal}
                disableBackdropClick={true}
                function={manageLogoutModal}
                className="logoutModal"
            >
                <h5>Are you sure you want to logout?</h5>

                <div className="actionWrapper">
                    <SecondaryButton
                        type="button"
                        onClick={manageLogoutModal}
                        label={'No, Cancel'}
                    />
                    <PrimaryButton type="button" onClick={() => onLogOut()} label={'Yes, Logout'} />
                </div>
            </CustomDialog>

            <CustomDialog
                title={'Update Details'}
                open={toggleProfileDialog}
                disableBackdropClick={true}
                function={changeProfileDialogStatus}
            >
                <h5>
                    {
                        'To ensure your account information is accurate, please let us know if there have been any changes to your details'
                    }
                </h5>
                <h5>{'Have your contact details changed recently?'}</h5>
                <h5>{'Do you need to update your address or other personal information?'}</h5>
                <h5>{'If yes, please click the button below, and we will assist you via email'}</h5>
                <div className="actionWrapper spaceButton">
                    <PrimaryButton
                        onClick={() => {
                            formikDetails.setFieldValue('profilechange', 1)
                            formikDetails.handleSubmit()
                        }}
                        id="confirm_bid_submit"
                        type="button"
                        label={'UPDATE REQUIRED'}
                    />
                    <SecondaryButton
                        onClick={() => {
                            formikDetails.setFieldValue('profilechange', 0)
                            formikDetails.handleSubmit()
                        }}
                        id="confirm_bid_submit"
                        type="button"
                        label={'NO CHANGES'}
                    />
                </div>
            </CustomDialog>

            <CustomDialog
                title={'Connection Disrupted'}
                open={toggleDisconnectDialog}
                disableBackdropClick={true}
                function={changeDisconnectDialogStatus}
            >
                <h5>{'It appears that your connection has been disrupted.'}</h5>
                <h5>{'Please click the Reload button or refresh your browser.'}</h5>

                <div className="actionWrapper">
                    <PrimaryButton type="button" onClick={() => refreshPage()} label={'RELOAD'} />
                </div>
            </CustomDialog>

            <CustomDialog
                title="Terms and Conditions"
                open={toggleDialog}
                className="ConfirmModal termsModal"
                disableBackdropClick
                function={changeDialogStatus}
            >
                <>
                    <div
                        className="text"
                        dangerouslySetInnerHTML={{
                            __html: termsContent ? termsContent : null,
                        }}
                    ></div>

                    <form className="trmActnWrpr">
                        <div className="row">{mapData(TermsBidding)}</div>
                        <div className="actionWrapper">
                            <PrimaryButton
                                onClick={formikProfile.handleSubmit}
                                id="confirm_bid_submit"
                                type="button"
                                label={'Submit'}
                            />
                        </div>
                    </form>
                </>
            </CustomDialog>
        </>
    )
}
export default Header
