import React, { useReducer } from 'react'
import AlbumContext from './albumContext'
import AlbumReducer from './albumReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import { GET_ALL_ALBUMS, GET_ALL_ALBUM_IMAGES, RESPONSE_STATUS, CLEAR_RESPONSE } from './albumTypes'

const AlbumState = (props) => {
    const initialState = {
        search_allalbums: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            from: '',
        },
        search_all_album_images: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            from: '',
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(AlbumReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllAlbums = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'search', formData, '', 'album')])
            const from = 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_ALBUMS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res.data.status,
                        message: res.data.data.message,
                        type: res.data.data.responseType,
                        from: from,
                    },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllAlbumImages = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'search_images', formData, '', `album`),
            ])
            const from = fromVariable ? fromVariable : 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_ALBUM_IMAGES,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const addWatchlistauction = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addAuctionWatchList', formData, '', 'auction'),
            ])
            resp.commonResponse(res.data, 'auctionwatchlist')
        } catch (err) {
            resp.commonErrorResponse('auctionwatchlist')
        }
    }

    const increaseAlbumViewCount = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'album_view_count', formData, '', 'album'),
            ])
            const from = 'similar'
            if (res.data.status === 'success') {
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const removeWatchlistauction = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'removeAuctionWatchList', formData, '', 'auction'),
            ])
            resp.commonResponse(res.data, 'auctionwatchlist')
        } catch (err) {
            resp.commonErrorResponse('auctionwatchlist')
        }
    }

    const downloadAlbumZIP = async (formData, fromVariable, albumName) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'download_album', formData, '', 'album', null, {
                    responseType: 'blob',
                }), // Handle blob response
            ])

            const blob = new Blob([res.data], { type: 'application/zip' })
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url

            // Extract filename from Content-Disposition header
            const contentDisposition = res.headers['content-disposition']
            let filename = `${albumName}.zip` // Default filename
            if (contentDisposition) {
                const matches = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
                if (matches && matches[1]) {
                    // Extract the filename without quotes
                    filename = matches[1].replace(/['"]/g, '')
                }
            }

            link.setAttribute('download', filename) // Use the extracted filename
            document.body.appendChild(link)
            link.click() // Trigger download
            document.body.removeChild(link)
        } catch (err) {
            resp.commonErrorResponse('downloadAlbum')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <AlbumContext.Provider
            value={{
                search_allalbums: state.search_allalbums,
                search_all_album_images: state.search_all_album_images,
                responseStatus: state.responseStatus,
                clearResponse,
                getAllAlbums,
                getAllAlbumImages,
                downloadAlbumZIP,
                increaseAlbumViewCount,
                addWatchlistauction,
                removeWatchlistauction,
            }}
        >
            {props.children}
        </AlbumContext.Provider>
    )
}

export default AlbumState
