import { GET_ALL_ALBUMS, GET_ALL_ALBUM_IMAGES, RESPONSE_STATUS, CLEAR_RESPONSE } from './albumTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_ALBUMS:
            return {
                ...state,
                search_allalbums: action.payload,
            }
        case GET_ALL_ALBUM_IMAGES:
            return {
                ...state,
                search_all_album_images: action.payload,
            }

        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }

        default:
            return state
    }
}
